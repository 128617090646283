import * as Sentry from '@sentry/nextjs'

import {
    AllCountriesPostfix,
    CountryAccessGroup,
    CountryAccessGroupClaim,
    CountryAccessGroupClaimPrefix,
    UsersCountryAccessInfo,
} from './country-access-group'
import { Role } from './role'

import { BusinessUnit } from '../../../lib/generated/graphql'

export type Claim = Role | CountryAccessGroupClaim

const isCountryAccessGroupClaim = (claim: Claim): claim is CountryAccessGroupClaim => claim.startsWith(CountryAccessGroupClaimPrefix)

const isRoleClaim = (claim: Claim): claim is Role => !claim.startsWith(CountryAccessGroupClaimPrefix)

export const filterRoles = (claims: Claim[]): Role[] => claims.filter(isRoleClaim)

const validBusinessUnits = Object.values(BusinessUnit)
    .map((businessUnit) => businessUnit.toLowerCase())
    .join('|')
const validRoles = Object.values(Role)
    .map((role) => role.toLowerCase())
    .join('|')

const validFormats = {
    allCountries: /^sg_lmt_view_all_countries$/i,
    // includes all roles in the format sg_lmt_[region]_[businessUnit]
    regionalAccess: new RegExp(`^sg_lmt_view_[a-z]+_(${validBusinessUnits})$`, 'i'),
    // includes all roles in the format sg_lmt_view_es_ib_[businessUnit]
    iberia: new RegExp(`^sg_lmt_view_es_ib_(${validBusinessUnits})$`, 'i'),
    roles: new RegExp(`^(${validRoles})$`, 'i'),
}

export const validateClaims = (claims: Claim[]) => {
    for (const claim of claims) {
        const isValidFormat = Object.values(validFormats).some((regex) => regex.test(claim))

        if (!isValidFormat) {
            console.warn('Invalid role', claim)

            Sentry.captureMessage('Invalid role', {
                fingerprint: [`invalid-role-${claim}`],
                extra: {
                    claim,
                    validFormats: Object.entries(validFormats).map(([key, regex]) => `${key}: ${regex}`),
                },
                level: 'log',
            })
        }
    }
}

export const filterCountryAccessGroups = (claims: Claim[]): UsersCountryAccessInfo[] => {
    return claims.filter(isCountryAccessGroupClaim).map((claim) => {
        const lowerCaseClaim = claim.toLowerCase()

        if (lowerCaseClaim.endsWith(AllCountriesPostfix)) {
            return {
                countryAccessGroup: AllCountriesPostfix.toUpperCase(),
            }
        }

        const [countryAccessGroup, businessUnit] = lowerCaseClaim.replace(`${CountryAccessGroupClaimPrefix}_`, '').split('_')

        return {
            countryAccessGroup: countryAccessGroup?.toUpperCase() as CountryAccessGroup,
            businessUnit: businessUnit?.toUpperCase() as BusinessUnit,
        }
    })
}
